import {createRouter, createWebHistory} from 'vue-router'
import MainLayout from "@/layouts/MainLayout.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import {inject} from "vue";
import {requestHttp} from "@/services/HttpRequest";

const routes = [
    {
        path: '/',
        name: 'mainLayout',
        component: MainLayout,
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
            },
            {
                path: '/',
                name: 'register',
                component: () => import(/* webpackChunkName: "about" */ '../views/RegistroView.vue')
            }, {
                path: '/register_complete',
                name: 'register_complete',
                component: () => import(/* webpackChunkName: "about" */ '../views/RegisterCompleteView.vue')
            }, {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
            },
            {
                path: '/prize',
                name: 'prize',
                component: () => import(/* webpackChunkName: "about" */ '../views/PrizeView.vue')
            },
            {
                path: '/points-details',
                name: 'points-details',
                component: () => import( '../views/PointDetailsView.vue')
            },
            {
                path: '/password-change',
                name: 'password-change',
                component: () => import(/* webpackChunkName: "about" */ '../views/NewPasswordView.vue')
            },
            {
                path: '/forgot-password',
                name: 'forgot-password',
                component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPasswordView.vue')
            },
            {
                path: '/register-complete',
                name: 'register-complete',
                component: () => import(/* webpackChunkName: "about" */ '../views/RegisterCompleteView.vue')
            },
            {
                path: '/factura',
                name: 'factura',
                component: () => import(/* webpackChunkName: "about" */ '../views/FacturaView.vue')
            },
            {
                path: '/factura-new',
                name: 'factura-new',
                component: () => import(/* webpackChunkName: "about" */ '../views/FacturaViewNuevo.vue')
            },
         /*   {
                path: '/ranking',
                name: 'ranking',
                component: () => import(/!* webpackChunkName: "about" *!/ '../views/RankingView.vue')
            }*/],

    },
    {path: '/:pathMatch(.*)*', name: 'notFount', component: NotFoundView},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    const rootScope = inject('rootScope')

    try {

        rootScope.loading = true;
        const response = await requestHttp({
            endpoint: "auth/authStatus",
        });

        rootScope.loading = false;
        const data = response.data;


        if (data.estado === 400) {
            localStorage.removeItem('token');
            rootScope.logged = false;
            rootScope.name = "";
            rootScope.user = 0;
            if ( to.name !== 'login' && to.name !== 'register' && to.name !== 'forgot-password') {
                next({name: 'login'});
            } else next();
        } else if (data.estado === 200) {
            const datosSession = data.data;
            rootScope.logged = datosSession.logueado;
            rootScope.name = datosSession.nombre;
            rootScope.user = datosSession.usuario;
            rootScope.saldo_creditos = datosSession.valor_creditos;
            rootScope.saldo_acumulado = datosSession.valor_acumulado;
            rootScope.registro_completo = datosSession.registro_completado;
            rootScope.tipo_cliente = datosSession.tipo_cliente;

            if (to.name !== 'password-change' && datosSession.restablecer_clave === 0) {
                next({name: 'password-change'});
            }

            if (to.name === 'login' || to.name === 'register') {
                next({name: 'home'});
            } else {
                next();
            }

        } else {
            rootScope.logged = false;
            localStorage.removeItem('token');
            next({name: 'login'});
        }

    } catch (error) {
        localStorage.removeItem('token');
        rootScope.logged = false;
        rootScope.loading = false;
        next({name: 'login'});
    }

});

export default router
