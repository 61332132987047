import axios from "axios";

export const HTTP_VERBS = {
    POST: "post",
    GET: "get",
    PUT: "put",
    DELETE: "delete",
};

export const CONTENT_TYPES = {
    APPLICATION_JSON: 'application/json',
    MULTIPART_FORM_DATA: 'multipart/form-data'
}

const headersConfig = (contentType, token) => {
    return {
        "Content-Type": contentType,
        accept: contentType,
        'authorization-token': 'Bearer ' + token
    }
};


export const requestHttp = async ({
                                      method = HTTP_VERBS.POST,
                                      endpoint = '/',
                                      body = {},
                                      params = {}, //query params
                                      contentType = CONTENT_TYPES.APPLICATION_JSON,
                                  }) => {
    const base = 'api/';
    const url = `${base}${endpoint}`;
    const authToken = localStorage.getItem('token') ?? '';
    const options = {
        url,
        method,
        data: body,
        params,
        headers: headersConfig(contentType, authToken)
    };
    return axios(options);

};
